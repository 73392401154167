<template>
  <BaseLayout wrapperClass="home">
    <HeroBanner />
    <ImageGallery />
    <SubscribeSeparator />
    <MostPurchased v-if="hrEnabled" />
    <Retargeted v-if="hrEnabled" />
    <OurStores />
    <OurBrands />
  </BaseLayout>
</template>

<script>
import { isServer } from "@storefront/core/helpers";
import config from "@config";

const BaseLayout = () =>
  import("@/esf_thehague_wieleroutfits/core/components/BaseLayout");
const HeroBanner = () => import("@/base/core/components/HeroBanner");
const ImageGallery = () => import("@/base/core/components/ImageGallery");
const SubscribeSeparator = () =>
  import("@/base/core/components/SubscribeSeparator");
const OurStores = () => import("@/base/core/components/OurStores");
const OurBrands = () => import("@/base/core/components/OurBrands");
const MostPurchased = () => import("@/base/core/components/home/MostPurchased");
const Retargeted = () => import("@/base/core/components/home/Retargeted.vue");

export default {
  name: "HomePage",
  components: {
    BaseLayout,
    HeroBanner,
    ImageGallery,
    SubscribeSeparator,
    OurStores,
    OurBrands,
    MostPurchased,
    Retargeted,
  },
  methods: {
    checkIsServer() {
      return isServer;
    },
  },
  computed: {
    cmsDefaultPageContent() {
      return this.$store.state.cmsPage.default;
    },
  },
  metaInfo() {
    return {
      meta: [
        {
          name: "title",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_title
            : "",
        },
        {
          name: "keywords",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_keywords
            : "",
        },
        {
          name: "description",
          content: this.cmsDefaultPageContent
            ? this.cmsDefaultPageContent.meta_description
            : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Home",
      // all titles will be injected into this template
    };
  },
  async mounted() {
    if (!isServer && config.helloRetail && config.helloRetail.enabled) {
      var hello_retail_id = this.$cookies.get("hello_retail_id");
      if (hello_retail_id) {
        await this.$store.dispatch("home/loadRetargetedProducts", {
          hello_retail_id,
          page: "home",
        });
        await this.$store.dispatch("home/loadMostPurchasedProducts", {
          hello_retail_id,
        });
      }
    }
  },
  data() {
    return {
      hrEnabled: config.helloRetail && config.helloRetail.enabled,
    };
  },
};
</script>
